<template>
  <div class="total-reward-container">
    <CCard>
      <CCardBody>
        <h1 class="title-total-reward">{{ $t('LIST_REWARD_PAGE_LABEL_TOTAL_REWARD') }}</h1>
        <SearchRewardHeader :onSearch="onSearch" />
        <ListTotalRewardTable :totalReward="totalReward" />
        <ListChildpoolCommission :childpoolCommission="childpoolCommission" />
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import SearchRewardHeader from '@/components/Reward/SearchRewardHeader';
import ListTotalRewardTable from '@/components/Reward/ListTotalRewardTable';
import ListChildpoolCommission from '@/components/Reward/ListChildpoolCommission';
import endpoints from '@/constants/endpoints';
import { mapState, mapActions } from 'vuex';
import responseHelper from '@/utils/response-helper';

export default {
  name: 'ListReward',
  components: {
    SearchRewardHeader,
    ListTotalRewardTable,
    ListChildpoolCommission,
  },
  data() {
    return {
      fillterData: {},
      totalReward: [],
      childpoolCommission: [],
      searchData: {},
    };
  },
  methods: {
    async onSearch(searchData) {
      const data = searchData;
      this.getRewards(data);
    },
    async getRewards(data) {
      try {
        data = data || {};
        this.filterData = {
          ...this.filterData,
          ...data,
        };
        const filterData = this.filterData;
        let params = {
          start_date: filterData.fromDate,
          end_date: filterData.toDate,
        };
        let result = await this.$http.get(endpoints.getRewards, { params });
        if (result.data) {
          (this.totalReward = result.data.total_reward), (this.childpoolCommission = result.data.childpool_commission);
        }
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('LIST_TOTAL_REWARD_PAGE_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
          duration: 2000,
        });
      }
    },
  },
};
</script>
<style lang="scss">
.total-reward-container {
  padding: 0 24px;
  @media screen and (max-width: 1024px) {
    padding: 0 15px;
  }
  .title-total-reward {
    color: #657187;
    // font-family: "SF UI Display";
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }
}
</style>
