var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"total-reward-table"},[_c('h1',{staticClass:"title-total-reward-table"},[_vm._v(_vm._s(_vm.$t('LIST_REWARD_PAGE_LABEL_TOTAL_REWARD_TABLE')))]),_c('CCard',[_c('CDataTable',{attrs:{"items":_vm.totalReward,"fields":_vm.fields,"fixed":"","hover":"","striped":"","bordered":"","pagination":""},scopedSlots:_vm._u([{key:"#",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',[_vm._v(_vm._s(index + 1))])]}},{key:"total_reward",fn:function(ref){
var item = ref.item;
return [(item.platform == 'XTZ')?_c('td',{staticStyle:{"text-align":"right","padding-right":"50px"}},[_c('span',[_vm._v(" Total: ")]),_vm._v(" "+_vm._s(item.total_reward)+" "),_c('br'),_c('span',[_vm._v(" Total Baker: ")]),_vm._v(" "+_vm._s(item.total_reward_baker)+" ")]):_c('td',{staticStyle:{"text-align":"right","padding-right":"50px"}},[_vm._v(" "+_vm._s(item.total_reward)+" ")])]}},{key:"validator_amount",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"text-align":"right","padding-right":"50px"}},[_vm._v(_vm._s(item.validator_amount))])]}},{key:"master_pool",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"text-align":"right","padding-right":"50px"}},[_vm._v(_vm._s(item.master_pool))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }