var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"childpoopl-commission-table"},[_c('h1',{staticClass:"title-childpool-commission-table"},[_vm._v(_vm._s(_vm.$t('LIST_REWARD_PAGE_LABEL_CHILDPOOL_COMMISSION_TABLE')))]),_c('CCard',[_c('CDataTable',{attrs:{"items":_vm.childpoolCommission,"fields":_vm.fields,"fixed":"","striped":"","bordered":""},scopedSlots:_vm._u([{key:"#",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',[_vm._v(_vm._s(index + 1))])]}},{key:"name",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(item.name))])]}},{key:"platform",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(item.platform))])]}},{key:"amount",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"text-align":"right","padding-right":"50px"}},[_vm._v(_vm._s(item.amount))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }