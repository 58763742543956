<template>
  <div class="total-reward-table">
    <h1 class="title-total-reward-table">{{ $t('LIST_REWARD_PAGE_LABEL_TOTAL_REWARD_TABLE') }}</h1>
    <CCard>
      <CDataTable :items="totalReward" :fields="fields" fixed hover striped bordered pagination>
        <template ##="{ item, index }">
          <td>{{ index + 1 }}</td>
        </template>
        <template #total_reward="{ item }">
          <td v-if="item.platform == 'XTZ'" style="text-align: right; padding-right: 50px">
            <span> Total: </span> {{ item.total_reward }} <br />
            <span> Total Baker: </span> {{ item.total_reward_baker }}
          </td>
          <td v-else style="text-align: right; padding-right: 50px">
            {{ item.total_reward }}
          </td>
        </template>
        <template #validator_amount="{ item }">
          <td style="text-align: right; padding-right: 50px">{{ item.validator_amount }}</td>
        </template>
        <template #master_pool="{ item }">
          <td style="text-align: right; padding-right: 50px">{{ item.master_pool }}</td>
        </template>
      </CDataTable>
    </CCard>
  </div>
</template>
<script>
export default {
  name: 'ListTotalRewardTable',
  data() {
    return {
      fields: [
        '#',
        {
          key: 'platform',
          label: this.$t('LIST_TOTAL_REWARD_TABLE_COMPONENT_LABEL_PLATFORM'),
          _style: 'text-align: center;',
        },
        {
          key: 'total_reward',
          label: this.$t('LIST_TOTAL_REWARD_TABLE_COMPONENT_LABEL_TOTAL_REWARD'),
          _style: 'text-align: right;padding-right:80px',
        },
        {
          key: 'validator_amount',
          label: this.$t('LIST_TOTAL_REWARD_TABLE_COMPONENT_LABEL_SELF_REWARD'),
          _style: 'text-align: right;padding-right:80px',
        },
        {
          key: 'master_pool',
          label: this.$t('LIST_TOTAL_REWARD_TABLE_COMPONENT_LABEL_MASTER_POOL'),
          _style: 'text-align: right;padding-right:80px',
        },
      ],
    };
  },
  props: {
    totalReward: {
      type: Array,
      default: [],
    },
  },
};
</script>
<style lang="scss">
.total-reward-table {
  margin: 35px 0px;
  .title-total-reward-table {
    color: #657187;
    // font-family: "SF UI Display";
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }
}
</style>
