<template>
  <div class="childpoopl-commission-table">
    <h1 class="title-childpool-commission-table">{{ $t('LIST_REWARD_PAGE_LABEL_CHILDPOOL_COMMISSION_TABLE') }}</h1>
    <CCard>
      <CDataTable :items="childpoolCommission" :fields="fields" fixed striped bordered>
        <template ##="{ item, index }">
          <td>{{ index + 1 }}</td>
        </template>
        <template #name="{item}">
          <td style="text-align: center">{{ item.name }}</td>
        </template>
        <template #platform="{item}">
          <td style="text-align: center">{{ item.platform }}</td>
        </template>
        <template #amount="{item}">
          <td style="text-align: right;padding-right: 50px;">{{ item.amount }}</td>
        </template>
      </CDataTable>
    </CCard>
  </div>
</template>
<script>
export default {
  name: 'ListTotalRewardTable',
  data() {
    return {
      fields: [
        '#',
        {
          key: 'name',
          label: this.$t('LIST_CHILDPOOL_COMMISSION_TABLE_COMPONENT_LABEL_PARTNER'),
          _style: 'text-align: center;width: 200px'
        },
        {
          key: 'platform',
          label: this.$t('LIST_CHILDPOOL_COMMISSION_TABLE_COMPONENT_LABEL_PLATFORM'),
          _style: 'text-align: center;'
        },
        {
          key: 'amount',
          label: this.$t('LIST_CHILDPOOL_COMMISSION_TABLE_COMPONENT_LABEL_COMMISSION'),
          _style: 'text-align: center;width: 200px'
        },
      ],
    };
  },
  props: {
    childpoolCommission: {
      type: Array,
      default: [],
    },
  },
};
</script>
<style lang="scss">
.childpoopl-commission-table {
  margin: 35px 0px;
  .title-childpool-commission-table {
    color: #657187;
    // font-family: "SF UI Display";
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }
}
</style>

